//==============================================================================
// External:

// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { createProject, getProjects } from "../slices/projects.slice";
import { setQueryTemplate } from "../slices/queryTemplates.slice";
import { setSubstitution } from "../slices/substitutions.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import environment from "../../utils/env";
//==============================================================================

export const createNewProjectEffect = async (action, { dispatch, getState }) => {
  try {
    const { error, navigate, title, userId } = action.payload;
    const newProjectData = { title, status: 'open', userId };

    // bail if title already exists:
    const projects = getProjects(getState())
    const titleAlreadyExists = Object.values(projects).map(project => project.title).includes(title);

    if (titleAlreadyExists) {
      dispatch(setFlashMessage({
        type: 'error',
        message: `Project ${title} already exists. Please enter a unique title.`
      }))
      dispatch(setModalContent(''));
      return;
    }

    // it title is not taken, save to DB:
    const returnedData = await fetchWithPost(
      `${environment.dbBaseURL}/projects`,
      { ...newProjectData },
    );

    dispatch(setModalContent(''));
    dispatch(createProject(returnedData.project));
    dispatch(setQueryTemplate(returnedData.queryTemplate));
    dispatch(setSubstitution(returnedData.substitution));

    if (error) {
      action.payload.setError('');
    }

    dispatch(setFlashMessage({
      type: 'success',
      message: `'${returnedData?.project?.title}' Project Successfully Created!`
    }))

    navigate(`/project/${returnedData.project._id}`)
  } catch (error) {
    action.payload.setError('Unable to submit new project. Please try again!')
    console.error('Error In CreateNewProjectEffect', error);
  }
}
//===================================================================================
// External
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal
import { useGetAgents } from '../../hooks/commonHooks';
import { setIsOpenValue } from '../../utils/utils';
//===================================================================================

const useAgentFormHooks = () => {
  const { id, agentId } = useParams();
  const agents = useGetAgents();
  const agent = agents[agentId];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(agent?.firstName || '');
  const [lastName, setLastName] = useState(agent?.lastName || '');
  const [agency, setAgency] = useState(agent?.agency || '');
  const [email, setEmail] = useState(agent?.email || '');
  const [agencySite, setAgencySite] = useState(agent?.agencySite || '');
  const [agencyGuidelines, setAgencyGuidelines] = useState(agent?.agencyGuidelines || '');
  const [socialMedia, setSocialMedia] = useState(agent?.socialMedia || '');
  const [queryManager, setQueryManager] = useState(agent?.queryManager || '');
  const [wishList, setWishList] = useState(agent?.wishList || '');
  const [publishersMarketplace, setPublishersMarketplace] = useState(agent?.publishersMarketplace || '');
  const [queryLetter, setQueryLetter] = useState(agent?.queryLetterRequired || false);
  const [synopsis, setSynopsis] = useState(agent?.synopsisRequired || false);
  const [pagesType, setPagesType] = useState(agent?.pagesType || '');
  const [pageCount, setPageCount] = useState(agent?.pageCount || 0);
  const [isOpen, setIsOpen] = useState(setIsOpenValue(agent));
  const [doNotQuery, setDoNotQuery] = useState(agent?.doNotQuery || false);
  const [rating, setRating] = useState(agent?.rating || 0);
  const [tags, setTags] = useState(agent?.tags || []);
  const [responseTime, setResponseTime] = useState(agent?.responseTime || 0);
  const [notes, setNotes] = useState(agent?.notes || '');
  const [queryFormat, setQueryFormat] = useState(agent?.queryFormat || '');

  return {
    agency,
    agencyGuidelines,
    agencySite,
    agent,
    agents,
    agentId,
    dispatch,
    doNotQuery,
    email,
    firstName,
    id,
    isOpen,
    lastName,
    navigate,
    notes,
    pageCount,
    pagesType,
    publishersMarketplace,
    queryFormat,
    queryLetter,
    queryManager,
    rating,
    responseTime,
    socialMedia,
    setAgencyGuidelines,
    setAgency,
    setAgencySite,
    setDoNotQuery,
    setEmail,
    setFirstName,
    setIsOpen,
    setLastName,
    setNotes,
    setPageCount,
    setPagesType,
    setPublishersMarketplace,
    setQueryFormat,
    setQueryLetter,
    setQueryManager,
    setRating,
    setResponseTime,
    setSocialMedia,
    setSynopsis,
    setTags,
    setWishList,
    synopsis,
    tags,
    wishList,
  }
}

export default useAgentFormHooks;

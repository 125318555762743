//===================================================================================
// External
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Sidebar as ReactSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

// Internal
import { useQueryTemplateId } from '../../hooks/commonHooks';
import { setModalContent } from '../../store/slices/ui.slice';
import useSidebarHooks from './Sidebar.hooks';
import { useNavigate } from 'react-router-dom';
//===================================================================================

const Sidebar = () => {
  const {
    dispatch,
    projects,
    setShouldShowProjectLinks,
    shouldShowProjectLinks,
  } = useSidebarHooks();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const queryTemplateId = useQueryTemplateId();

  const openModal = () => {
    dispatch(setModalContent('createNewProject'))
  }

  const handleProjectClick = ({ title, _id }) => {
    // if (shouldShowProjectLinks === title) {
      // click on project title returns user to dashboard
      // setShouldShowProjectLinks('');
      // navigate(`/dashboard`);
    // } else {
      setShouldShowProjectLinks(title);
      navigate(`/project/${_id}`);
    // }
  };

  //const hasProjects = () => Object.keys(projects).length > 0;

  const shouldDisplayDropdownContent = (title) => {
    return shouldShowProjectLinks === title
  };

  const titleContainerStyles = {
    borderBottom: !collapsed && 'none',
  }

  const projectList = Object.values(projects).map((project) => {
    const { _id, title } = project;

    return (
      <Menu>
        <div className="Sidebar__titleContainer" style={titleContainerStyles} onClick={() => handleProjectClick({ title, _id })}>
          <FontAwesomeIcon icon="fa-book" />
          {!collapsed ? <div className="Sidebar__title">{title}</div> : <>&nbsp;</>}
        </div>
        {
          shouldDisplayDropdownContent(title) &&
          <div className="Sidebar__submenuContainer">
            <SubMenu
              label="Query Template"
              icon={<FontAwesomeIcon icon="fa-pen-nib" />}
              onClick={() => navigate(`/project/${_id}/query-template/${queryTemplateId}`)}
            />
            <SubMenu
              label={"Agent List"}
              icon={<FontAwesomeIcon icon="fa-user-tie"/>}
              onClick={() => navigate(`/project/${_id}/agents`)}
            />
            <SubMenu
              label={"Queries"}
              icon={<FontAwesomeIcon icon="fa-clipboard-check" />}
              onClick={() => navigate(`/project/${_id}/queries`)}
            />
          </div >
        }
      </Menu >
    )
  });

  return (
    <div className="Sidebar__container" >
      <ReactSidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setCollapsed(!collapsed)}
        onBreakPoint={setBroken}
        onClick={() => setToggled(!toggled)}
        rootStyles={{ height: '100%' }}
      >
        <div className="Sidebar__headerContainer">
          <FontAwesomeIcon icon="fa-bars" onClick={() => setCollapsed(!collapsed)} />
          <div className='Sidebar__projectHeader' >
            {!collapsed ? `Projects` : <>&nbsp;</>}
          </div>
        </div>
        <div className='Sidebar__projectList'>
          {projectList}
        </div>
        {
          !collapsed &&
          <div className="Sidebar__linkContainer" onClick={() => openModal()}>
            {/* <div className={`Sidebar__buttonContainer ${!hasProjects() ? 'radiating-button' : ''}`}> */}
            <div className={`Sidebar__buttonContainer`}>
              <FontAwesomeIcon icon="fa-plus-circle" className="Sidebar__plusIcon " />
              <div className="Sidebar__link">New Project</div>
            </div>
          </div>
        }
      </ReactSidebar>
    </div>

  )
}
export default Sidebar;
//===================================================================================
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleUp,
  faBan,
  faBars,
  faBold,
  faBook,
  faCheckCircle,
  faClipboardCheck,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faFeather,
  faFile,
  faGripVertical,
  faHourglassHalf,
  faItalic,
  faLink,
  faListOl,
  faMinusCircle,
  faPaperPlane,
  faPen,
  faPenNib,
  faPlusCircle,
  faPowerOff,
  faQuestion,
  faStar,
  faTrash,
  faUnderline,
  faUser,
  faUserTie,
  faUsers,
 } from "@fortawesome/free-solid-svg-icons";
//===================================================================================

function initFontAwesome() {
  library.add(faAlignCenter);
  library.add(faAlignJustify);
  library.add(faAlignLeft);
  library.add(faAlignRight);
  library.add(faAngleDown);
  library.add(faAngleUp);
  library.add(faBan);
  library.add(faBars);
  library.add(faBold);
  library.add(faBook);
  library.add(faCheckCircle);
  library.add(faClipboardCheck);
  library.add(faEnvelope);
  library.add(faEnvelopeOpen);
  library.add(faExclamationCircle);
  library.add(faFeather);
  library.add(faFile);
  library.add(faGripVertical);
  library.add(faHourglassHalf);
  library.add(faItalic);
  library.add(faLink);
  library.add(faListOl);
  library.add(faMinusCircle);
  library.add(faPaperPlane);
  library.add(faPen);
  library.add(faPenNib);
  library.add(faPlusCircle);
  library.add(faPowerOff);
  library.add(faQuestion);
  library.add(faStar);
  library.add(faTrash);
  library.add(faUnderline);
  library.add(faUser);
  library.add(faUserTie)
  library.add(faUsers);
}

export default initFontAwesome;

//==============================================================================
// External:
import React, { useEffect } from 'react';

// Internal:
//==============================================================================

const CookieBannerContent = ({ }) => {
  useEffect(() => {
    deleteButton();
  }, []);

  return (
    <div className='CookieBanner__container'>
      <h3>Querier Uses Cookies.</h3>
      <p>
        We only use essential cookies to give you the best possible experience and keep your information secure.
        Click “Accept All Cookies” to proceed using Querier securely, or click “Manage My Preferences” to view all cookies.
        See our <a href="/cookie-policy">Cookie Policy</a> for more information.</p>
    </div>
  )
}

const CookieManagementContent = () => {
  useEffect(() => {
    deleteButton();
    deleteButton('Accept selected');
  }, []);

  return (
    <div>
      <h3>Cookie Settings.</h3>
      <p>
        Querier uses only essential cookies. These cookies can not be disabled as they are necessary for the site to
        function properly and keep your data secure. Please select "Accept All" to proceed.
      </p>
    </div>
  )
}

const deleteButton = (text = "Decline Non-Essential Cookies") => {
  const buttons = document.querySelectorAll('button')
  let button;
  buttons?.forEach(btn => {
    if (btn.innerHTML === text) {
      button = btn;
    }
  })
  button.remove()
}


export { CookieBannerContent, CookieManagementContent };

//==============================================================================
// External:
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal:
import { deleteUser } from '../../store/actions/actions';
import { getUserDetails } from '../../store/slices/user.slice';
import { useNavigate } from 'react-router-dom';
//==============================================================================

const Profile = ({setShowProfile}) => {
  const user = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickHandler = () => {
    setShowProfile();
    dispatch(deleteUser({navigate}));
  }

  return (
    <div className='Profile__container'>
      <div className='Profile__innerContainer'>
        <div className='Profile__picture'>
          <img src={user.picture} className="Profile__pictureImg" alt='profile picture'/>
        </div>
        <div className='Profile__name'>
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div className='Profile__email'>
          {user.email}
        </div>
        {/* <div className='Profile__statsContainer'>
          <div className='Profile__stat'>
            <div className='Profile__statTitle'>Projects</div>
            <div className='Profile__statValue'>2</div>
          </div>
          <div className='Profile__stat'>
            <div className='Profile__statTitle'>Queries</div>
            <div className='Profile__statValue'>24</div>
          </div>
          <div className='Profile__stat'>
            <div className='Profile__statTitle'>Agents Added</div>
            <div className='Profile__statValue'>35</div>
          </div>
        </div> */}
        <div className='Profile__buttonContainer'>
          <button onClick={() => onClickHandler()}>Delete Profile</button>
        </div>
      </div>
    </div>
  )
}

export default Profile;

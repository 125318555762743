//==============================================================================
// Internal:
import { setFlashMessage, setLoading } from "../slices/ui.slice";
import { setThread } from "../slices/thread.slice";
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { getUserDetails } from "../slices/user.slice";
//==============================================================================

export const gmailGetThreadEffect = async (action, { dispatch, getState }) => {
  try {
    dispatch(setLoading());
    const {query} = action.payload;
    const user = getUserDetails(getState());

    const returnedThread = await fetchAPI(
      `${environment.dbBaseURL}/mail/user/${user._id}/thread/${query.threadId}`,
    );

    dispatch(setThread(returnedThread))
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Sending Your Email. Please Try Again.'}))
  } finally {
    dispatch(setLoading());
  }
};
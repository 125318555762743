//==============================================================================
/* External */
import {
  configureStore,
  createListenerMiddleware
} from '@reduxjs/toolkit';

/* Internal */
import rootReducer from './root.reducer';
import rootListener from './effects/root.effect';
//==============================================================================

const listenerMiddleware = createListenerMiddleware();

const {startListening} = listenerMiddleware;
rootListener(startListening);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) => {
    return getDefaultMiddleWare({ thunk: false, serializableCheck: false }).prepend(listenerMiddleware.middleware);
  },
  // devTools: process.env.BUILD_TYPE !== 'production',
  devTools: true, // TODO: remove this
});

export default store;

//==============================================================================
/* External */

/* Internal */
import { makeUUID } from "./utils";
//==============================================================================

const fetchAPI = async (url, method = 'GET', params = {}) => {
  const finalParams = {
    ...params,
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-Request-UUID': makeUUID()
    }
  };

  return fetch(url, finalParams)
    .then((response) => {
      if (!response.ok) {
        const httpError = {status: response.status, url: response.url};
        throw httpError;
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

const fetchWithPost = async (url, body = {}, method = 'POST', params = {}, headers = {}) => {
  // const csrfToken = Cookies.get('XSRF-TOKEN');
  params = {
    ...params,
    method,
    body: JSON.stringify(body),
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-UUID': makeUUID(),
      // 'X-XSRF-TOKEN': csrfToken,
    },
  };

  const response = await fetch(url, params);
  if (!response.ok) {
    const text = await response.text();
    throw text;
  }

  const data = response.json();
  return data;
};

export default fetchAPI;
export {fetchWithPost};

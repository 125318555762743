//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
import { updateQueryAPICall } from "../effectUtils/apiCalls";
import environment from "../../utils/env";
import {
  APP_USER_APPROVE_SEND_EMAIL_TO_AGENT,
  APP_USER_CANCEL_SEND_EMAIL_TO_AGENT
} from "../../utils/actionConstants";
import { objHasData } from "../../utils/utils";
import { setQuery } from "../slices/queries.slice";
//==============================================================================

export const gmailSendEmailEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate, projectId, query, ...payload } = action.payload;
    const user = getUserDetails(getState())

    dispatch(setModalContent('googleSendEmailToAgentConfirmation'));

    const actionsArray = [
      APP_USER_APPROVE_SEND_EMAIL_TO_AGENT,
      APP_USER_CANCEL_SEND_EMAIL_TO_AGENT,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    if (newAction.type === APP_USER_APPROVE_SEND_EMAIL_TO_AGENT) {
      const url = `${environment.dbBaseURL}/mail/user/${user._id}/send/${query.gmailDraftId}`;
      const emailSentConfirmation = await fetchAPI(url);

      if(objHasData(emailSentConfirmation)) {
        const updatedQuery = await updateQueryAPICall(query, { dateSentToAgent: new Date(), status: 'waiting' }, query._id);
        dispatch(setQuery(updatedQuery));
        dispatch(setFlashMessage({ type: 'success', message: 'Email Successfully Sent To Agent!' }))
        navigate(`/project/${projectId}/queries`);
      }
    }
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Sending Your Email. Please Try Again.' }))
  }
};
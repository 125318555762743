//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { makeObjFromArray } from "../../utils/utils";
import { setAgents } from "../slices/agents.slice";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const updateAgentPriorityEffect = async (action, { dispatch, getState }) => {
  try {
    // organize data to send:
    const { newAgentList, projectId, setOrderHasChanged } = action.payload;
    const userId = getUserId(getState());

    // see note 1:
    const prioritiesArray = agentObjToArray(newAgentList);

    // call endpoint:
    const url = `${environment.dbBaseURL}/user/${userId}/project/${projectId}/agents/priorities`;
    const body = { prioritiesArray, userId };
    const newAgentsArray = await fetchWithPost(url, body, 'PUT');

    // change returned array to obj and put in state:
    const newAgentsObj = makeObjFromArray(newAgentsArray);
    dispatch(setAgents(newAgentsObj));

    // clean up:
    setOrderHasChanged(false);

  } catch (error) {
    console.error('Error In UpdateAgentEffect: ', error)
    dispatch(setFlashMessage({ type: 'error', message: 'Unable To Update Agent List. Please Try Again.' }))
  }
};

const agentObjToArray = (newAgentList) => {
  return newAgentList.reduce((accumulator, agent, index) => {
    const incrementedIndex = index + 1; // because index is 0-based
    if (agent.priority !== incrementedIndex) {
      accumulator.push({ _id: agent._id, priority: incrementedIndex });
      return accumulator;
    }
    return accumulator
  }, [])
}


/*
NOTES:
1. Loop through new agent array (agent index = new priority). If agent existing priority doesn't equal the
   new index, the priority has changed, so push into prioritiesArray, resulting in array of objects of agents
   with new priorities.
*/

//==============================================================================
// External:
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Internal:
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import { useGetQueries } from '../../hooks/commonHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteQuery, simpleUpdateQuery } from '../../store/actions/actions';
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay';
import QueryStatusDropdown from '../Dropdown/QueryStatusDropdown';

//==============================================================================

const CustomFormReview = ({ }) => {
  const { queryId } = useParams();
  const query = useGetQueries()[queryId];
  const [dateSentToAgent, setDateSentToAgent] = useState(query.dateSentToAgent);
  const [queryStatus, setQueryStatus] = useState(query.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(simpleUpdateQuery({
      queryId: query._id,
      dateSentToAgent,
      status: queryStatus
    }));
  }

  return (
    <div className='CustomFormReview__container'>
      <PageTitleBar title={'Agent Form Review'} subTitle={'Review Info Submitted To Agent'} />
      <form className='CustomFormReview__formContainer' onSubmit={(event) => onSubmit(event)}>
        <div className='CustomFormReview__contentContainer'>
          <p>This is some explanation for what to do here:</p>
          <div className='CustomFormReview__dateSubmittedContainer'>
            <label className='CustomFormReview__agentInfoLabel'>Date Submitted To Agent:</label>
            <label>
              <DatePicker
                selected={dateSentToAgent}
                onChange={(date) => setDateSentToAgent(date)} //only when value has changed
              />
            </label>
            <QuestionDisplay tooltipKey={'customFormDate'} />
          </div>
          <QueryStatusDropdown value={queryStatus} onChangeHandler={setQueryStatus} />
        </div>
        <div className='CustomFormReview__buttonContainer'>
          <button className='CustomFormReview__formButton'>Submit</button>
          <button type="button" onClick={() => onDeleteEntireQuery()} className='CustomFormReview__deleteButton red'>Delete Query</button>
        </div>
      </form>
    </div>
  )
}

export default CustomFormReview;

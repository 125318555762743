//===================================================================================
// External
import React, { useState } from 'react'
//===================================================================================

const TagsInput = ({ tags, setTags }) => {
  const MAX_TAGS = 5;
  const [userInput, setUserInput] = useState(" ");

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission or new line creation

      if (
        userInput.trim() !== "" &&
        userInput.length <= 12 &&
        tags.length < MAX_TAGS
      ) {
        handleAddTag(userInput);
        setUserInput('');
      }
    }
  };

  const handleAddTag = (newTag) => {
    if (newTag && !tags.includes(newTag) && tags.length < MAX_TAGS) {
      setTags([...tags, newTag]);
    }
  };

  const handleRemoveTag = (tag) =>
    setTags(tags.filter((t) => t !== tag));

  const setPlaceholder = () => (tags.length < MAX_TAGS)
    ? "Add up to 5 tags (12 characters max each)"
    : `You can only enter max. of ${MAX_TAGS} tags`

  return (
    <form className="TagsInput__container" onSubmit={() => handleAddTag(userInput)}>
      <input
        name="keyword_tags"
        type="text"
        placeholder={setPlaceholder()}
        className="TagsInput__input"
        onKeyDown={handleKeyPress}
        onChange={handleInputChange}
        value={userInput}
        disabled={tags.length === MAX_TAGS}
      />
      <div className="TagsInput__itemsContainer">
        {tags?.map((tag, index) => (
          <span
            key={`${index}-${tag}`}
            className="TagsInput__item"
          >
            {tag}
            <div
              className="TagsInput__itemButton"
              onClick={() => handleRemoveTag(tag)}
              title={`Remove ${tag}`}
            >
              X
            </div>
          </span>
        ))}
      </div>
    </form>
  );
}

export default TagsInput;

/*
NOTES:
1. Source: https://dev.to/stephengade/create-tags-input-field-in-reactjs-s-no-package-required-5ae4
*/

//==============================================================================
// External:
import React from 'react';

// Internal:
import AgentForm from '../../components/AgentForm/AgentForm';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
//==============================================================================

const AgentEdit = () => {
  return (
    <div className='AgentEdit__container' >
      <PageSubtitle
        tooltipKey='newAgentForm'
        title='Edit Agent Form'
        onClickHandler={() => { }}
        shouldShowQuestionMark
      />
      <div className='AgentEdit__formContainer'>
        <AgentForm editForm />
      </div>
    </div>
  )
}

export default AgentEdit;
const helpRegistry = {
  agentList: `TODO: This is the agent list!`,
  agentRating: 'TODO: Rate this agent!',
  customFormDate: `Custom form date setting`,
  doNotQuery: `TODO: Do Not Query This Agent!`,
  editor: `TODO: Add your query template text. Be sure to include text substitutions!`,
  formattedText: `TODO: Example display of your email text, showing how text substitutions will display in the query (see purple text)`,
  newAgentForm: `TODO: This is a new agent form`,
  pagesDropdown: `TODO: This is the pages dropdown. \nIf Query Manager, check what the agent's form specifies.`,
  pagesEditor: `TODO: This text is for pages editor`,
  pagesEditorDropdown: `TODO: This test is for pages editor dropdown`,
  queryBuilderEmailSubject: `TODO: Default email subject for your query letter. Can be changed for individual queries later if an agent requires a different format.`,
  queryBuilderTemplateTitle: `TODO: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
  in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
  mollit anim id est laborum.`,
  synopsis: `TODO: This is a snyopsis tooltip`,
  synopsisEditor: `TODO: This text goes above Synopsis Editor`,
  textSubstitution: `Text substitutions allow you to customize each query by replacing the substitution with actual text. To create a text substitution:
  1. Click the ‘+’ button
  2. Give the substitution a name
  3. Add the text signifier: a word wrapped in double curly braces (eg, "{{my_text}}")
  4. Add an Example Text to test in the Formatted Text field. This text will appear in purple for illustration purposes only.
  5. Add the signifier in the query template text.
  6. Save the substitution!`,
  queryManagerFinalText: `TODO: Query Manager final email explanation`,
  queryBuilderAgent: `TODO: This is query builder agent tooltip`,
  queryBuilderAgentReqs: `TODO: This is query builder agent reqs tooltip`,
  queryBuilderSubstitutionEntries: `TODO: This is query builder sub entries`,
  queryBuilderEditor: `TODO: This is query builder editor tooltip`,
  queryFormat: `TODO: This is text for query format - email or QueryManager`,
  queryManagerDate: `Input date the query was submitted to QM`,
  queryManagerPersonalLink: 'TODO: QueryManagerPersonalLink info',
  responseTime: `TODO: Amount of time agent/agency states they take to reply to a query. They may ask you to resubmit your query of consider the query unaccepted if they haven't responded within this timeframe.`,
  substitutionFormCancel: `TODO: Substitution Form Cancel`,
  substitutionFormDelete: `TODO: Substitution Form Delete`,
  substitutionFormSubmit: `TODO: Substitution Form Submit`,
}

export const getTooltipText = (key) => {
  return helpRegistry[key]
}

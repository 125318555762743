//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { getExpirationTime, setModalContent } from "../slices/ui.slice";
import { setExpirationTime } from "../slices/user.slice";
import {
  APP_EXTEND_SESSION,
  APP_CONT_LOGOUT_COUNTDOWN
} from "../../utils/actionConstants";
import { logout } from "../actions/actions";
import environment from "../../utils/env";
//==============================================================================

export const startSessionExpirationEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate, resetTimer } = action.payload;

    // call /ping endpoint and get session expirary time
    const expirationTime = getExpirationTime(getState())

    // start countdown that will open modal three minutes before expiration time
    const timeToOpenModal = (expirationTime - Date.now()) - (3 * 60 * 1000)
    console.log('START EXPIRATION TIME EFFECT: ', {expirationTime, timeToOpenModal});
    const setIntervalId = setTimeout(() => {
      if (document.visibilityState === 'visible') {
        console.log('START SESSION EXP EFFECT: window active, opening timer modal',);
        dispatch(setModalContent('countdownModal'));
      } else {
        console.log('START SESSION EXP EFFECT: window not active, logging out',);
        dispatch(logout({ navigate }))
        return
      }
    }, timeToOpenModal);

    // when  modal opened, pause and wait for action:
    const actionsArray = [
      APP_EXTEND_SESSION,
      APP_CONT_LOGOUT_COUNTDOWN,
      // 'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    // if 'continue' action is fired from 'resetTimer':
    if (newAction.type === APP_EXTEND_SESSION) {
      console.log('START SESSION EXP EFFECT: extending session',);
      clearTimeout(setIntervalId) // stop modal-opening timer from above
      const {expirationTime} = await fetchAPI(`${environment.dbBaseURL}/extend-session`);
      dispatch(setExpirationTime(expirationTime));
      resetTimer()
      dispatch(setModalContent(''));
      return
    }

    // if 'close' is clicked or modal completes countdown:
    if (newAction.type === APP_CONT_LOGOUT_COUNTDOWN) {
      console.log('START SESSION EXP EFFECT: use chose to close session',);
      clearTimeout(setIntervalId)
      dispatch(setModalContent(''));
      dispatch(logout({ navigate }))
      return
    }

  } catch (error) {
    console.error('Error in StartSessionExpirationEffect: ', error);
  }
};
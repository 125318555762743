//==============================================================================
// External:
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Internal:
import Loading from '../../components/Loader/Loading';
import { initialize } from '../../store/actions/actions';
import { getUserDetails } from '../../store/slices/user.slice';
import { objHasData } from '../../utils/utils';
//==============================================================================

const IntermediateLanding = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserDetails);

  useEffect(() => {
    if (!objHasData(user)) {
      dispatch(initialize({navigate}));
    }
  }, []);

  return (
    <div className='IntermediateLanding__container'>
      <Loading/>
    </div>
  )
}

export default IntermediateLanding;

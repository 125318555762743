//==============================================================================
// Internal:
import { APP_EXTEND_SESSION } from "../../utils/actionConstants";
import { checkSessionExpiration } from "../actions/actions";
import { getShowModal } from "../slices/ui.slice";
//==============================================================================

export const handleIdleSessionEffect = async (action, { dispatch, getState, take }) => {
  try {
    const {navigate} = action.payload;
    let HAS_STARTED_COUNTDOWN = false;
    const timeoutInMiliseconds = 2 * 60 * 1000;
    let timeoutId;

    function startTimer() {
      timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds);
    }

    function resetTimer() {
      // fire 'cancel' action to kill timers in saga (if saga has been called)
      window.clearTimeout(timeoutId);
      startTimer();

      const modalContent = getShowModal(getState())
      if (modalContent === "countdownModal") return;

      if (HAS_STARTED_COUNTDOWN) {
        dispatch({ type: APP_EXTEND_SESSION })
        HAS_STARTED_COUNTDOWN = false;
      }
    }

    function doInactive() {
      console.log("---------- CLIENT INACTIVE ---------");
      dispatch(checkSessionExpiration({ navigate, resetTimer }));
      HAS_STARTED_COUNTDOWN = true;
    }

    function setupTimers() {
      document.addEventListener("onload", resetTimer, false);
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("onclick", resetTimer, false);
      document.addEventListener("keydown", resetTimer, false);
      document.addEventListener("scroll", resetTimer, false);

      startTimer();
    }

    setupTimers();
  } catch (error) {
    console.error('Error in HandleIdleSessionEffect: ', error);
  }
}

//==============================================================================
/* External */
import { createSlice } from '@reduxjs/toolkit';
//==============================================================================

const initialState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserDetails() {
      return initialState;
    },
    setUserDetails(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setUser(state, action) {
      // only used for testing currently
      return {
        ...action.payload,
      }
    },
    setExpirationTime(state, action) {
      state.expirationTime = action.payload
    },
  },
});

// selectors:
export const getTokenExpirationTime = (state) => state.user.expirationTime
export const getUserDetails = (state) => state.user
export const getUserId = (state) => state.user._id;
export const isAuthenticated = (state) => Boolean(state.user._id)

// actions:
export const {
  clearUserDetails,
  setUser,
  setUserDetails,
  setExpirationTime
} = userSlice.actions;

// reducer:
export default userSlice.reducer;

//===================================================================================
// External:
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Internal:
import QuillImage from "../../assets/quill.svg";
import { logout } from "../../store/actions/actions";
import { getUserDetails, isAuthenticated } from "../../store/slices/user.slice";
import Profile from "../Profile/Profile";
//===================================================================================

const NavBar = () => {
  const isAuthenticatedUser = useSelector(isAuthenticated);
  const user = useSelector(getUserDetails);
  const [showProfile, setShowProfile] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout({ navigate }));
  }

  return (
    <div className="Nav__container">
      <div className="Nav__title">
        <img src={QuillImage} className="Nav__logo" />
        Querier
      </div>
      <div className="Nav__links">
        <div className="Nav__commonLinks">
          {
            isAuthenticatedUser &&
            <NavLink className="Nav__link" to="/dashboard" >
              Dashboard
            </NavLink>
          }
          <NavLink className="Nav__link" to='/about'>
            How It Works
          </NavLink>
        </div>
        <div className="Nav__authLinks">
          {
            isAuthenticatedUser ?
            <button onClick={() => onLogout()}>Sign Out</button>
            : <NavLink to="/sign-in">Sign In</NavLink>
          }
        </div>
        <div className="Nav__pictureContainer" onClick={() => setShowProfile(!showProfile)}>
          {
            isAuthenticatedUser && <img src={user.picture} className="Nav__picture" alt='profile picture'/>
          }
        </div>
        {showProfile && <Profile setShowProfile={setShowProfile}/>}
      </div>
    </div>
  );
};

export default NavBar;

//==============================================================================
// External:
import React from 'react';

// Internal:
import AgentForm from '../../components/AgentForm/AgentForm';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
//==============================================================================

const AgentNew = () => {

  return (
    <div className='AgentNew__container' >
      <PageSubtitle
        tooltipKey='newAgentForm'
        title='New Agent Form'
        onClickHandler={() => { }}
        shouldShowQuestionMark
      />
      <div className='AgentNew__formContainer'>
        <AgentForm />
      </div>
    </div>
  )
}

export default AgentNew;

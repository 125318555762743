//==============================================================================
// External:
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal:
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import { deleteQuery } from '../../store/actions/actions';
import { useGetQueries } from '../../hooks/commonHooks';
//==============================================================================

const QueryManagerReview = () => {
  const { queryId } = useParams();
  const query = useGetQueries()[queryId];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  const onOpenQueryManager = () => {
    window.open(query.queryManagerPersonalLink, '_blank').focus();
  }

  return (
    <div className='QueryManagerReview__container'>
      <PageTitleBar title={'Query Manager Review'} subTitle={'Review QueryManager Status'} />
      <iframe
        className='QueryManagerReview__iframe'
        src={query.queryManagerPersonalLink}
      />
      <div className='QueryManagerReview__buttons'>
        <button
          className='QueryManagerReview__openQMButton'
          onClick={() => onOpenQueryManager()}
          disabled={!query.queryManagerPersonalLink}
        >
          Go To QueryManager
        </button>
        <button
          className='QueryManagerReview__deleteButton red'
          onClick={() => onDeleteEntireQuery()}
          type="button"
        >
          Delete Query
        </button>
      </div>
    </div>
  )
}

export default QueryManagerReview;

//===================================================================================
// External:
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookieBanner } from "@schlomoh/react-cookieconsent";

// Internal:
import About from "../../views/About/About";
import AgentEdit from "../../views/AgentEdit/AgentEdit";
import AgentNew from "../../views/AgentNew/AgentNew";
import AgentSelect from "../AgentSelect/AgentSelect";
import Agents from "../../views/Agents/Agents";
import CookiePolicy from "../../views/Misc/CookiePolicy";
import Dashboard from "../Dashboard/Dashboard";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import FlashMessage from "../FlashMessage/FlashMessage";
import Footer from "../Footer/Footer";
import Home from "../../views/Home/Home";
import IntermediateLanding from "../../views/IntermediateLanding/IntermediateLanding";
import Loading from "../Loader/Loading";
import ModalHandler from "../ModalHandler/ModalHandler";
import NavBar from "../NavBar/NavBar";
import PrivacyPolicy from "../../views/Misc/PrivacyPolicy";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Project from "../Project/Project";
import QueryBuilder from "../../views/QueryBuilder/QueryBuilder";
import QueryCreate from "../QueryCreate/QueryCreate";
import QueryEdit from "../QueryEdit/QueryEdit";
import QueryFinalReview from "../../views/QueryFinalReview/QueryFinalReview";
import QueryTemplate from "../../views/QueryTemplate/QueryTemplate";
import QueryThread from "../../views/QueryThread/QueryThread";
import QueryManagerReview from "../../views/QueryManagerReview/QueryManagerReview";
import QueryManagerSubmit from "../../views/QueryManagerSubmit/QueryManagerSubmit";
import Sidebar from "../Sidebar/Sidebar";
import SignIn from "../SignIn/SignIn";
import TermsOfUse from "../../views/Misc/TermsOfUse";
import {
  CookieBannerContent,
  CookieManagementContent
} from "../CookieBannerContent/CookieBannerContent";

// Misc
import { useLocationListener } from "../../hooks/useLocationListener";
import initFontAwesome from "../../utils/initFontAwesome";
import CustomFormReview from "../CustomFormReview/CustomFormReview";
//===================================================================================
initFontAwesome();

const App = () => {
  const location = useLocationListener();
  const [shouldShowSidebar, setShouldShowSidebar] = useState(false);

  console.log(`Querier is loaded in the ${process.env.NODE_ENV} environment`);

  useEffect(() => {
    document.addEventListener('visibilitychange', function (event) {
      if (document.hidden) {
        console.log('Querier: not visible');
      } else {
        console.log('Querier: visible');
      }
    });
  }, [])

  useEffect(() => {
    const whitelistedURLs = ['/', '/about', '/privacy-policy', '/terms-of-use', '/sign-in', '/cookie-policy']
    if (!whitelistedURLs.includes(location) && location !== null) {
      setShouldShowSidebar(true);
    } else {
      setShouldShowSidebar(false);
    }
  }, [location])

  const setStyles = () => {
    const blacklistedURLs = ['/', '/sign-in', null]
    return {
      padding: !blacklistedURLs.includes(location) ? '25px' : ''
    }
  };

  const onAcceptCookies = (cookies) => {
    const cookie = document.cookie;
    const newCookie = `${cookie};expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`; //1 year out
    document.cookie = newCookie;
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <div className="AppRouter__container">
          <NavBar />
          <div
            className="AppRouter__pageContainer"
          >
            <CookieBanner
              enableManagement
              acceptButtonText='Accept All'
              declineButtonText='Decline Non-Essential Cookies'
              managementButtonText='Manage My Preferences'
              infoContent={<CookieBannerContent />}
              managementContent={<CookieManagementContent />}
              primaryButtonStyle={{ backgroundColor: '#874CD7' }}
              onAccept={onAcceptCookies}
            />
            <FlashMessage />
            <ModalHandler />
            <Loading />
            {shouldShowSidebar && <Sidebar />}
            <div className="AppRouter__contentContainer" style={setStyles()}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/landing' element={<IntermediateLanding />} />
                <Route path='/sign-in' element={<SignIn />} />
                <Route path="/auth/redirect/google" element={<SignIn />} />
                <Route path='/about' element={<About />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-of-use' element={<TermsOfUse />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />

                <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path='/project/:id/agent-edit/:agentId' element={<PrivateRoute><AgentEdit /></PrivateRoute>} />
                <Route path='/project/:id/agent-new' element={<PrivateRoute><AgentNew /></PrivateRoute>} />
                <Route path='/project/:id/agent-select/:queryTemplateId' element={<PrivateRoute><AgentSelect /></PrivateRoute>} />
                <Route path='/project/:id/agent/:queryTemplateId' element={<PrivateRoute><AgentNew /></PrivateRoute>} />
                <Route path='/project/:projectId/agents' element={<PrivateRoute><Agents /></PrivateRoute>} />
                <Route path='/project/:id/query-template/:queryTemplateId' element={<PrivateRoute><QueryTemplate /></PrivateRoute>} />
                <Route path='/project/:projectId' element={<PrivateRoute><Project /></PrivateRoute>} />
                <Route path='/project/:projectId/queries' element={<PrivateRoute><QueryBuilder /></PrivateRoute>} />
                <Route path='/project/:projectId/query-create' element={<PrivateRoute><QueryCreate /></PrivateRoute>} />
                <Route path='/query-final-review/:queryId' element={<PrivateRoute><QueryFinalReview /></PrivateRoute>} />
                <Route path='/query-thread/:queryId' element={<PrivateRoute><QueryThread /></PrivateRoute>} />
                <Route path='/query-manager-submit/:queryId' element={<PrivateRoute><QueryManagerSubmit /></PrivateRoute>} />
                <Route path='/query-manager-review/:queryId' element={<PrivateRoute><QueryManagerReview /></PrivateRoute>} />
                <Route path='/agent-custom-form-review/:queryId' element={<PrivateRoute><CustomFormReview /></PrivateRoute>} />
                <Route path='/query/:queryId' element={<PrivateRoute><QueryEdit /></PrivateRoute>} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;

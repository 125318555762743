//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { fetchProjects } from "../actions/actions";
import { setUserDetails } from "../slices/user.slice";
//==============================================================================

export const initializeEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate } = action.payload;

    // Call for current user:
    const currentUserURL = `${environment.dbBaseURL}/current-user`;
    const currentUser = await fetchAPI(currentUserURL)
    dispatch(setUserDetails(currentUser));

    // TODO: can we call this effect directly?
    // Get Projects:
    dispatch(fetchProjects())
    const [newAction, ...rest] = await take(action => action.type === 'projects/setProjects'); // slice action

    navigate('/dashboard')
  } catch (error) {
    console.error('Error in InitializeEffect: ', error);
  }
}

//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setProjects } from "../slices/projects.slice";
import { getUserId } from "../slices/user.slice";
import environment from "../../utils/env";
//==============================================================================

export const getProjetEffect = async (action, { dispatch, getState }) => {
  const userId = getUserId(getState());
  const projects = await fetchAPI(`${environment.dbBaseURL}/projects/${userId}`);

  let projectsObj;
  if (projects.length > 0) {
    projectsObj = projects.reduce((accumulator, project) => {
      accumulator[project._id] = project;
      return accumulator;
    }, {});
  }

  dispatch(setProjects(projectsObj));
};
//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setFlashMessage } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
import { getAgents } from "../slices/agents.slice";
import { getQueries } from "../slices/queries.slice";
import environment from "../../utils/env";
//==============================================================================

export const gmailUpdateDraftEffect = async (action, { dispatch, getState }) => {
  try {
    const {queryId, agentId, navigate} = action.payload;
    const user = getUserDetails(getState())
    const agent = getAgents(getState())[agentId];
    const query = getQueries(getState())[queryId];

    const requestBody = {
      to: agent.email,
      from: user.email,
      subject: query.emailSubject,
      message: query.finalEmailText,
    }

    const url = `${environment.dbBaseURL}/mail/user/${user._id}/update/${query.gmailDraftId}`;
    await fetchWithPost(url, {...requestBody});

    dispatch(setFlashMessage({ type: 'success', message: 'Draft Successfully Updated!' }))
    navigate(`/project/${query.projectId}/queries`);
  } catch (error) {
    console.error('Error in GmailUpdateDraft Effect:', error);
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Updating Your Email Draft. Please Try Again.' }))
  }
};
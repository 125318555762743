//===================================================================================
// External:
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

// Internal:
import App from "./components/App/App";
import store from './store/store';
import "./styles/_Main.scss";
import './assets/fonts/Raleway-Regular.ttf';
//===================================================================================

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

//==============================================================================
// External:
import React from 'react';

// Internal:
import { makeInput } from '../../../../utils/utils';
import PopoutWindow from '../../../../assets/window_popout.png'
import Dropdown from '../../../Dropdown/Dropdown';
import QuestionDisplay from '../../../QuestionDisplay/QuestionDisplay';
//==============================================================================

const QueryManagerForm = ({ queryManagerProps }) => {
  const {
    queryManager,
    setQueryManager,
    setPagesType,
    pagesType,
    pageCount,
    setPageCount
  } = queryManagerProps;

  return (
    <>
      <div className='AgentForm__queryManager inputContainer'>
        <div className='AgentForm__labelPopoutContainer'>
          <div className='AgentForm__label'>Query Manager Link: </div>
          {
            queryManager &&
            <a href={`${queryManager}`} target="_blank">
              <img src={PopoutWindow} className='AgentForm__popoutIcon' />
            </a>
          }
        </div>
        {makeInput(setQueryManager, queryManager, 'queryManager', 'Query Manager Address')}
        <div className='AgentForm__pagesType'>
          <div className='AgentForm__label'>Sample Pages Type: </div>
          <div className='AgentForm__pagesDropdownContainer'>
            <Dropdown setValue={setPagesType} value={pagesType} />
            <div className='AgentForm__questionDisplay'>
              <QuestionDisplay tooltipKey={'pagesDropdown'} />
            </div>
          </div>
        </div>
        {
          pagesType &&
          <div className='AgentForm__pageCount​'>
            <div className='AgentForm__label'> {`${pagesType} Count:`} </div>
            {makeInput(setPageCount, pageCount, 'pageCountInput', `Number of ${pagesType}`)}
          </div>
        }
      </div >
    </>
  )
}

export default QueryManagerForm;
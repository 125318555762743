//===================================================================================
// External:
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Internal:
import WhyQuerier from "./Subcomponents/WhyQuerier";
import HowDoesQuerierWork from "./Subcomponents/HowDoesQuerierWork";
import FAQs from "./Subcomponents/FAQs";
//===================================================================================

const Home = () => {
  const { loginWithRedirect } = useAuth0();
  const [imageHeight, setImageHeight] = useState('');

  const calcImageHeight = () => {
    const ele = document.querySelector('.Home__mainImage');
    const height = ele?.getBoundingClientRect()?.height;
    return `${height - 25}px`;
  }

  useEffect(() => {
    setImageHeight(calcImageHeight());
  }, [])

  return (
    <div className="Home__container">
      <div className="Home__mainImage"></div>
      <div className="Home__textContainer" style={{ height: imageHeight }}>
        <div className="Home__textInnerContainer">
          <div className="Home__textLine">
            Querying Sucks.
          </div>
          <div className="Home__textLine">
            Querier Can Make It Suck Less.
          </div>
          <button onClick={() => loginWithRedirect()}>
            Start Querying Today
          </button>
        </div>
      </div>
      <WhyQuerier/>
      <HowDoesQuerierWork/>
      <FAQs/>
    </div>
  )
};

export default Home;

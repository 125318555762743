//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI, {fetchWithPost } from "../../utils/fetchAPI";
//==============================================================================

export const gmailDeleteDraftAPICall = async (userId, gmailDraftId) => {
  const url = `${environment.dbBaseURL}/mail/user/${userId}/delete/${gmailDraftId}`;
  const returnedGmailInfo = await fetchAPI(url);
  return returnedGmailInfo;
}

export const updateQueryAPICall = async (existingQueryInfo, newQueryInfo, queryId) => {
  const url = `${environment.dbBaseURL}/queries/${queryId}`;
  const queryBody = { ...existingQueryInfo, ...newQueryInfo };
  const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
  return updatedQuery;
}